<template>
  <div>
    <b-row>
      <b-col lg="12">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Afficher</label>
                <v-select
                  v-model="perPage"
                  :dir="'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                  @input="fetchForecasts(true)"
                />
                <label>entrées</label>
              </b-col>
              <!-- Search -->
              <!--              <b-col
                cols="12"
                md="6"
              >
                <b-form @submit.prevent="fetchForecasts(true)">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-form-input
                      v-model="searchQuery"
                      class="d-inline-block mr-1"
                      placeholder="Rechercher..."
                    />
                    <b-button
                      type="submit"
                      variant="primary"
                    >
                      <span class="text-nowrap">Chercher</span>
                    </b-button>
                  </div>
                </b-form>
              </b-col>-->
            </b-row>
          </div>
          <b-table
            ref="refForecastListTable"
            class="position-relative"
            :items="forecasts"
            responsive
            :fields="tableColumns"
            primary-key="uuid"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="Aucun résultat trouvé"
            :sort-desc.sync="isSortDirDesc"
            :no-local-sorting="true"
            :busy="loadingForecasts"
          >
            <!-- busy -->
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner label="Loading..." />
              </div>
            </template>
            <!-- Column: user -->
            <template #cell(user)="data">
              <b-link :to="{name: 'users-view', params: {id:data.item.user.uuid}}">
                {{ data.item.user.name }}
              </b-link>
            </template>
            <!-- Column: pts_won -->
            <template #cell(pts_won)="data">
              <template v-if="forecastType==='fixture'">
                {{ data.item.pts_won }} /  {{ pointsToWin(fixture) }}
              </template>
              <template v-if="forecastType === 'question'">
                {{ data.item.pts_won }} /  {{ question.points_to_win }}
              </template>
            </template>
            <!-- Column: forecast -->
            <template #cell(forecast)="data">
              <template v-if="forecastType==='fixture'">
                <span>
                  {{ data.item.local_team_score }} - {{ data.item.visitor_team_score }}
                  <b-img
                    v-if="data.item.winner_team"
                    height="auto"
                    width="15"
                    :src="data.item.winner_team.logo_path"
                  />
                </span>
              </template>
              <template v-if="forecastType==='question'">
                <span v-if="data.item.answer">
                  {{ data.item.answer.title['fr'] }}
                  <b-img
                    height="auto"
                    width="15"
                    :src="data.item.answer.image"
                  />
                </span>
              </template>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span
                  class="text-muted"
                >Affichage de {{ dataMeta.from }} à {{ dataMeta.to }} sur
                  {{ dataMeta.total }} entrées</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalForecasts"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @change="changePage($event)"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormInput,
  BImg, BLink,
  BListGroup,
  BListGroupItem, BPagination,
  BRow,
  BSpinner, BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'CompetitionsViewForecastsList',
  components: {
    BBadge,
    BRow,
    BCol,
    BCard,
    BButton,
    BImg,
    BSpinner,
    BListGroup,
    BListGroupItem,
    vSelect,
    BForm,
    BFormInput,
    BTable,
    BPagination,
    BLink,
  },
  setup() {
    const resolveStatusVariant = status => {
      if (status === 'PENDING') return 'light-info'
      if (status === 'RUNNING') return 'light-success'
      if (status === 'FINISHED') return 'light-warning'
      return 'light-success'
    }
    const resolveStatusGoodForecast = bool => (bool ? 'light-success' : 'light-danger')
    const pointsToWin = fixture => {
      const { stage } = fixture
      if (stage === '8th Finals' || stage === 'Quarter-finals' || stage === 'Semi-finals' || stage === '3rd Place Final' || stage === 'Final') {
        return '6'
      }
      return '3'
    }
    return {
      avatarText,
      resolveStatusVariant,
      pointsToWin,
      resolveStatusGoodForecast,
    }
  },
  props: {
    apiUrl: {
      required: true,
      type: String,
    },
    forecastType: {
      required: true,
      type: String,
    },
    fixture: {
      type: null,
      default: null,
    },
    question: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      loadingForecasts: true,
      perPageOptions: [5, 25, 50, 100],
      forecasts: [],
      perPage: 25,
      searchQuery: '',
      sortBy: 'name',
      isSortDirDesc: false,
      tableColumns: [
        {
          key: 'user',
          label: 'Utilisateur',
          sortable: false,
        },
        {
          key: 'pts_won',
          label: 'points gagnés',
          sortable: false,
        },
        {
          key: 'forecast',
          label: 'Pronostic',
          sortable: false,
        },
      ],
      currentPage: 1,
      totalForecasts: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
        total: 0,
      },
    }
  },
  mounted() {
    this.fetchForecasts()
  },
  methods: {
    async fetchForecasts() {
      try {
        this.loadingForecasts = true
        const { data } = await axiosIns(this.apiUrl, {
          params: {
            perPage: this.perPage,
            page: this.currentPage,
          },
        })
        this.forecasts = data.data
        this.loadingForecasts = false
        this.dataMeta.from = data.meta.form
        this.dataMeta.to = data.meta.to
        this.dataMeta.total = data.meta.total
        this.totalForecasts = data.meta.total
      } catch (err) {
        this.loadingForecasts = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    changePage(e) {
      this.currentPage = e
      this.fetchForecasts()
    },
  },
}
</script>

<style scoped>

</style>
