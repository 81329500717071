<template>
  <div>
    <b-row>
      <b-col lg="12">
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <b-card v-else>
          <div>
            <!-- Platform Info: Input Fields -->

            <validation-observer ref="questionValidation">
              <b-form @submit.prevent="validationFormQuestion">
                <b-row>
                  <!-- Field: title_fr -->
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group
                      label="Titre fr"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="titre fr"
                        rules="required|max:200"
                      >
                        <b-form-input
                          v-model="question.title.fr"
                          placeholder="Quelle équipe marquera le plus de but ?"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['title_fr']"
                          class="text-danger"
                        >{{ server_errors['title_fr'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: title_en -->
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group
                      label="Titre en"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="titre en"
                        rules="required|max:200"
                      >
                        <b-form-input
                          v-model="question.title.en"
                          placeholder="Which team will score the most goals?"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['title_en']"
                          class="text-danger"
                        >{{ server_errors['title_en'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: title_de -->
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group
                      label="Titre de"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="titre de"
                        rules="required|max:200"
                      >
                        <b-form-input
                          v-model="question.title.de"
                          placeholder="Which team will score the most goals?"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['title_de']"
                          class="text-danger"
                        >{{ server_errors['title_de'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: title_it -->
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group
                      label="Titre it"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="titre it"
                        rules="required|max:200"
                      >
                        <b-form-input
                          v-model="question.title.it"
                          placeholder="Which team will score the most goals?"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['title_it']"
                          class="text-danger"
                        >{{ server_errors['title_it'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: title_es -->
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group
                      label="Titre es"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="titre es"
                        rules="required|max:200"
                      >
                        <b-form-input
                          v-model="question.title.es"
                          placeholder="Which team will score the most goals?"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['title_es']"
                          class="text-danger"
                        >{{ server_errors['title_es'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: points_to_win -->
                  <b-col
                    cols="12"
                    md="6"
                    lg="3"
                  >
                    <b-form-group
                      label="Points"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="points"
                        rules="required|max_value:99|min_value:1"
                      >
                        <b-form-input
                          id="slot"
                          v-model="question.points_to_win"
                          type="number"
                          placeholder="10"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['points_to_win']"
                          class="text-danger"
                        >{{ server_errors['points_to_win'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: status -->
                  <b-col
                    cols="12"
                    md="6"
                    lg="3"
                  >
                    <b-form-group
                      label="Status"
                    >
                      <v-select
                        v-model="question.status"
                        :dir="'ltr'"
                        :options="statusOptions"
                        :clearable="false"
                        :reduce="val => val.value"
                      />
                      <small
                        v-if="server_errors && server_errors['status']"
                        class="text-danger"
                      >{{ server_errors['status'][0] }}</small>
                    </b-form-group>
                  </b-col>

                  <!-- Field: date_time -->
                  <b-col
                    cols="12"
                    md="6"
                    lg="3"
                  >
                    <b-form-group
                      label="Date Limite"
                    >
                      <flat-pickr
                        v-model="question.date_time"
                        class="form-control"
                        :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss', time_24hr : true}"
                      />
                      <small
                        v-if="server_errors && server_errors['date_time']"
                        class="text-danger"
                      >{{ server_errors['date_time'][0] }}</small>
                    </b-form-group>
                  </b-col>

                  <!-- Field: is_visible -->
                  <b-col
                    cols="12"
                    md="6"
                    lg="3"
                  >
                    <b-form-group
                      label="Visibilité"
                    >
                      <b-form-checkbox
                        v-model="question.is_visible"
                        :value="true"
                        :unchecked-value="false"
                      >
                        <span class="vs-label">{{ question.is_visible ? 'Visible' : 'Invisible' }}</span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <!-- Field: good answer -->
                  <b-col
                    cols="12"
                    md="6"
                    lg="3"
                  >
                    <b-form-group
                      label="Bonne réponse"
                    >
                      <v-select
                        v-model="question.good_answer_id"
                        :get-option-label="option => option.title.fr"
                        :dir="'ltr'"
                        :options="answers"
                        :reduce="val => val.uuid"
                      />
                      <small
                        v-if="server_errors && server_errors['good_answer_id']"
                        class="text-danger"
                      >{{ server_errors['good_answer_id'][0] }}</small>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Action Buttons -->
                <div class="d-flex justify-content-between">
                  <b-button
                    variant="primary"
                    type="submit"
                    :disabled="updateQuestionFormLoading || !isQuestionModified"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  >
                    <b-spinner
                      v-show="updateQuestionFormLoading"
                      small
                    />
                    Enregistrer
                  </b-button>
                  <b-button
                    v-b-modal="`modal-delete-question-${question.uuid}`"
                    variant="danger"
                    :disabled="deleteQuestionLoading"
                    type="button"
                  >
                    <b-spinner
                      v-show="deleteQuestionLoading"
                      small
                    />
                    Supprimer
                  </b-button>
                  <b-modal
                    :id="`modal-delete-question-${question.uuid}`"
                    :ref="`modal-delete-question-${question.uuid}`"
                    ok-variant="danger"
                    ok-title="Supprimer"
                    modal-class="modal-danger"
                    centered
                    title="Suppression de la question"
                  >
                    <b-card-text>
                      Souhaitez-vous vraiment supprimer cette question ? <br>
                      Ces éléments seront supprimés : <br>
                      -Pronostiques fait sur cette réponse
                      (Le classement sera recalculé)
                    </b-card-text>
                    <template #modal-footer>
                      <b-button
                        variant="secondary"
                        @click="hideModal()"
                      >
                        annuler
                      </b-button>
                      <b-button
                        variant="danger"
                        :disabled="deleteQuestionLoading"
                        @click="deleteQuestion()"
                      >
                        <b-spinner
                          v-show="deleteQuestionLoading"
                          small
                        />
                        supprimer
                      </b-button>
                    </template>
                  </b-modal>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card title="Ajouter une réponse">
          <div>
            <validation-observer ref="answerValidation">
              <b-form @submit.prevent="validationFormAnswer">
                <b-row>
                  <!-- Field: title_fr -->
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group
                      label="Titre fr"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="titre fr"
                        rules="required|max:100"
                      >
                        <b-form-input
                          v-model="answer.title_fr"
                          placeholder="France"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['title_fr']"
                          class="text-danger"
                        >{{ server_errors['title_fr'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: title_en -->
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group
                      label="Titre en"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="titre en"
                        rules="required|max:100"
                      >
                        <b-form-input
                          v-model="answer.title_en"
                          placeholder="France"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['title_en']"
                          class="text-danger"
                        >{{ server_errors['title_en'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: title_de -->
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group
                      label="Titre de"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="titre de"
                        rules="required|max:100"
                      >
                        <b-form-input
                          v-model="answer.title_de"
                          placeholder="Frankreich"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['title_de']"
                          class="text-danger"
                        >{{ server_errors['title_de'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: title_it -->
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group
                      label="Titre it"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="titre it"
                        rules="required|max:100"
                      >
                        <b-form-input
                          v-model="answer.title_it"
                          placeholder="Francia"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['title_it']"
                          class="text-danger"
                        >{{ server_errors['title_it'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: title_es -->
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group
                      label="Titre es"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="titre es"
                        rules="required|max:100"
                      >
                        <b-form-input
                          v-model="answer.title_es"
                          placeholder="Francia"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['title_es']"
                          class="text-danger"
                        >{{ server_errors['title_es'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: image -->
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group
                      label="Image"
                    >
                      <b-form-file
                        v-model="answer.image"
                        accept="image/jpeg, image/png, image/gif"
                        placeholder="Choisissez un fichier ou déposez-le ici..."
                        drop-placeholder="Déposer le fichier ici..."
                      />
                      <small
                        v-if="server_errors && server_errors['image']"
                        class="text-danger"
                      >{{ server_errors['image'][0] }}</small>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Action Buttons -->
                <b-button
                  variant="primary"
                  type="submit"
                  :disabled="addAnswerFormLoading"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                >
                  <b-spinner
                    v-show="addAnswerFormLoading"
                    small
                  />
                  Ajouter
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="question">
      <CompetitionsViewQuestionsViewAnswer
        v-for="answerEl in answers"
        :key="answerEl.id"
        :answer="answerEl"
        :question="question"
        @answerDelete="onDeleteAnswer"
        @answerImageUpdate="onUpdateAnswerImage"
      />
    </b-row>
    <CompetitionsViewForecastsList
      :forecast-type="'question'"
      :question="question"
      :api-url="api_url"
    />
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import CompetitionsViewQuestionsViewAnswer from '@/views/competitions/competitions-view/questions-view/CompetitionsViewQuestionsViewAnswer'
import CompetitionsViewForecastsList from '@/views/competitions/competitions-view/forecasts-list/CompetitionsViewForecastsList'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCard,
  BCol,
  BForm, BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow, BSpinner,
  BFormFile, BModal, VBModal, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  max, max_value, min, min_value, required,
} from '@core/utils/validations/validations'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'CompetitionsViewQuestionsView',
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BFormCheckbox,
    vSelect,
    BSpinner,
    flatPickr,
    BFormFile,
    CompetitionsViewQuestionsViewAnswer,
    CompetitionsViewForecastsList,
    BModal,
    BCardText,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      loading: true,
      updateQuestionFormLoading: false,
      addAnswerFormLoading: false,
      deleteQuestionLoading: false,
      question: null,
      required,
      max,
      min,
      max_value,
      min_value,
      questionReference: null,
      server_errors: null,
      statusOptions: [
        { label: 'À venir', value: 'PENDING' },
        { label: 'En cours', value: 'RUNNING' },
        { label: 'Terminé', value: 'FINISHED' },
      ],
      answer: {
        title_fr: '',
        title_en: '',
        title_de: '',
        title_it: '',
        title_es: '',
        image: null,
      },
      answers: [],
      api_url: `/questions/${this.$route.params.id}/forecasts`,
    }
  },
  computed: {
    isQuestionModified() {
      return JSON.stringify(this.question) !== this.questionReference
    },
  },
  mounted() {
    this.fetchQuestion()
  },
  beforeDestroy() {
    this.$store.commit('app/UPDATE_PAGE_META', { pageTitle: '', breadcrumb: [] })
  },
  methods: {
    async fetchQuestion() {
      try {
        this.loading = true
        const { data } = await axiosIns(`questions/${this.$route.params.id}`)
        this.question = {
          date_time: data.data.date_time,
          is_mail_reminder_sent: data.data.is_mail_reminder_sent,
          is_visible: data.data.is_visible,
          points_to_win: data.data.points_to_win,
          status: data.data.status,
          title: data.data.title,
          uuid: data.data.uuid,
          good_answer: data.data.good_answer,
          good_answer_id: data.data.good_answer ? data.data.good_answer.uuid : null,
        }
        this.answers = data.data.answers
        this.questionReference = JSON.stringify(this.question)
        this.$store.commit('app/UPDATE_PAGE_META', {
          pageTitle: this.question.title.fr,
          breadcrumb: [
            {
              text: 'Competitions',
              active: false,
              to: {
                name: 'competitions',
              },
            },
            {
              text: this.$route.params.idCompetition,
              active: false,
              to: {
                name: 'competitions-view',
                params: { id: this.$route.params.idCompetition },
              },
            },
            {
              text: 'Questions',
              active: true,
            },
            {
              text: this.question.title.fr,
              active: true,
            },
          ],
        })
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async submitFormQuestion() {
      try {
        this.updateQuestionFormLoading = true
        this.server_errors = null
        const data = {
          title_fr: this.question.title.fr,
          title_en: this.question.title.en,
          title_de: this.question.title.de,
          title_it: this.question.title.it,
          title_es: this.question.title.es,
          status: this.question.status,
          date_time: this.question.date_time,
          points_to_win: this.question.points_to_win,
          is_visible: this.question.is_visible,
          good_answer_id: this.question.good_answer_id,
        }
        const question = await axiosIns.post(`questions/${this.$route.params.id}`, data)
        this.question = {
          date_time: question.data.data.date_time,
          is_mail_reminder_sent: question.data.data.is_mail_reminder_sent,
          is_visible: question.data.data.is_visible,
          points_to_win: question.data.data.points_to_win,
          status: question.data.data.status,
          title: question.data.data.title,
          uuid: question.data.data.uuid,
          good_answer: question.data.data.good_answer,
          good_answer_id: question.data.data.good_answer ? question.data.data.good_answer.uuid : null,
        }
        this.answers = question.data.data.answers
        this.questionReference = JSON.stringify(this.question)
        this.updateQuestionFormLoading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La question à bien été mise a jour',
            },
          },
          { timeout: 5000 },
        )
      } catch (err) {
        this.updateQuestionFormLoading = false
        this.server_errors = err.response.data.errors
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async submitFormAddAnswer() {
      try {
        this.addAnswerFormLoading = true
        this.server_errors = null
        const data = new FormData()
        data.append('title_fr', this.answer.title_fr)
        data.append('title_en', this.answer.title_en)
        data.append('title_de', this.answer.title_de)
        data.append('title_it', this.answer.title_it)
        data.append('title_es', this.answer.title_es)
        data.append('image', this.answer.image)
        data.append('question_id', this.$route.params.id)
        const answer = await axiosIns.post('answers', data)
        this.answer.title_fr = ''
        this.answer.title_en = ''
        this.answer.title_de = ''
        this.answer.title_it = ''
        this.answer.title_es = ''
        this.answer.image = null
        // on enleve les erreurs
        this.$refs.answerValidation.reset()
        this.answers.push(answer.data.data)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La réponse à bien été ajoutée',
            },
          },
          { timeout: 5000 },
        )
        this.addAnswerFormLoading = false
      } catch (err) {
        this.addAnswerFormLoading = false
        this.server_errors = err.response.data.errors
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async deleteQuestion() {
      try {
        this.deleteQuestionLoading = true
        await axiosIns.delete(`questions/${this.question.uuid}`)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La question a été supprimée',
            },
          },
          { timeout: 5000 },
        )
        this.deleteQuestionLoading = false
        this.hideModal()
        await this.$router.push({
          path: `/competitions/${this.$route.params.idCompetition}`,
        })
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
        this.deleteQuestionLoading = false
      }
    },
    validationFormQuestion() {
      this.$refs.questionValidation.validate().then(success => {
        if (success) {
          this.submitFormQuestion()
        }
      })
    },
    validationFormAnswer() {
      this.$refs.answerValidation.validate().then(success => {
        if (success) {
          this.submitFormAddAnswer()
        }
      })
    },
    onDeleteAnswer(answerUuid) {
      const newAnswerArray = this.answers.filter(obj => obj.uuid !== answerUuid)
      this.answers = newAnswerArray
    },
    onUpdateAnswerImage(answer) {
      // on met a jour la visualisation de l'image avec le lien s3
      const objIndex = this.answers.findIndex(obj => obj.uuid === answer.uuid)
      this.answers[objIndex].image = answer.image
    },
    hideModal() {
      this.$refs[`modal-delete-question-${this.question.uuid}`].hide()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
