<template>
  <b-col
    xl="3"
    lg="4"
    md="6"
  >
    <b-card>
      <template #header>
        <h4 class="mb-0">
          Modifier la réponse
        </h4>
        <b-badge
          v-show="(question.good_answer?question.good_answer.uuid : null) === answer.uuid"
          variant="light-success"
        >
          bonne réponse
          <feather-icon icon="CheckIcon" />
        </b-badge>
      </template>
      <div>
        <validation-observer ref="answerValidation">
          <b-form @submit.prevent="validationForm">
            <b-row>
              <!-- Field: title_fr -->
              <b-col
                cols="12"
                lg="12"
              >
                <b-form-group
                  label="Titre fr"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="titre fr"
                    rules="required|max:100"
                  >
                    <b-form-input
                      v-model="answer.title.fr"
                      placeholder="France"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="server_errors && server_errors['title_fr']"
                      class="text-danger"
                    >{{ server_errors['title_fr'][0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: title_en -->
              <b-col
                cols="12"
                lg="12"
              >
                <b-form-group
                  label="Titre en"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="titre en"
                    rules="required|max:100"
                  >
                    <b-form-input
                      v-model="answer.title.en"
                      placeholder="France"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="server_errors && server_errors['title_en']"
                      class="text-danger"
                    >{{ server_errors['title_en'][0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: title_de -->
              <b-col
                cols="12"
                lg="12"
              >
                <b-form-group
                  label="Titre de"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="titre de"
                    rules="required|max:100"
                  >
                    <b-form-input
                      v-model="answer.title.de"
                      placeholder="Frankreich"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="server_errors && server_errors['title_de']"
                      class="text-danger"
                    >{{ server_errors['title_de'][0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: title_it -->
              <b-col
                cols="12"
                lg="12"
              >
                <b-form-group
                  label="Titre it"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="titre it"
                    rules="required|max:100"
                  >
                    <b-form-input
                      v-model="answer.title.it"
                      placeholder="Francia"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="server_errors && server_errors['title_it']"
                      class="text-danger"
                    >{{ server_errors['title_it'][0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: title_es -->
              <b-col
                cols="12"
                lg="12"
              >
                <b-form-group
                  label="Titre es"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="titre es"
                    rules="required|max:100"
                  >
                    <b-form-input
                      v-model="answer.title.es"
                      placeholder="Francia"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="server_errors && server_errors['title_es']"
                      class="text-danger"
                    >{{ server_errors['title_es'][0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: image -->
              <b-col
                cols="12"
                lg="12"
              >
                <template v-if="isImgLink">
                  <b-form-group label="Image">
                    <b-img
                      height="auto"
                      width="50"
                      :src="answer.image"
                    />
                    <b-button
                      variant="warning"
                      class="ml-1 btn-icon"
                      @click="removeImage"
                    >
                      <feather-icon icon="RefreshCwIcon" />
                    </b-button>
                  </b-form-group>
                </template>
                <template v-else>
                  <b-form-group
                    label="Image"
                  >
                    <b-form-file
                      v-model="answer.image"
                      accept="image/jpeg, image/png, image/gif"
                      placeholder="Choisissez un fichier ou déposez-le ici..."
                      drop-placeholder="Déposer le fichier ici..."
                      :state="Boolean(answer.image)"
                    />
                    <small
                      v-if="server_errors && server_errors['image']"
                      class="text-danger"
                    >{{ server_errors['image'][0] }}</small>
                  </b-form-group>
                </template>
              </b-col>
            </b-row>
            <!-- Action Buttons -->
            <div class="d-flex justify-content-between">
              <b-button
                variant="primary"
                type="submit"
                :disabled="formLoading || !isAnswerModified"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              >
                <b-spinner
                  v-show="formLoading"
                  small
                />
                Mettre à jour
              </b-button>
              <b-button
                v-b-modal="`modal-delete-answer-${answer.uuid}`"
                variant="danger"
                type="button"
                :disabled="deleteLoading"
              >
                <b-spinner
                  v-show="deleteLoading"
                  small
                />
                Supprimer
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
      <b-modal
        :id="`modal-delete-answer-${answer.uuid}`"
        :ref="`modal-delete-answer-${answer.uuid}`"
        ok-variant="danger"
        ok-title="Supprimer"
        modal-class="modal-danger"
        centered
        title="Suppression de la réponse"
      >
        <b-card-text>
          Souhaitez-vous vraiment supprimer cette réponse ? <br>
          Ces éléments seront supprimés : <br>
          -Pronostiques fait sur cette réponse
          (Le classement sera recalculé)
        </b-card-text>
        <template #modal-footer>
          <b-button
            variant="secondary"
            @click="hideModal()"
          >
            annuler
          </b-button>
          <b-button
            variant="danger"
            :disabled="deleteLoading"
            @click="deleteAnswer()"
          >
            <b-spinner
              v-show="deleteLoading"
              small
            />
            supprimer
          </b-button>
        </template>
      </b-modal>
    </b-card>
  </b-col>
</template>

<script>
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCard,
  BCol,
  BForm, BFormFile, BCardText,
  BFormGroup,
  BFormInput,
  BRow, BSpinner,
  BImg,
  BBadge, BModal, VBModal,
} from 'bootstrap-vue'
import {
  max, max_value, min, min_value, required,
} from '@core/utils/validations/validations'

export default {
  name: 'CompetitionsViewQuestionsViewAnswer',
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardText,
    BSpinner,
    BFormFile,
    BImg,
    BBadge,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    answer: {
      required: true,
      type: Object,
    },
    question: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      formLoading: false,
      deleteLoading: false,
      required,
      max,
      min,
      max_value,
      min_value,
      server_errors: null,
      answerReference: null,
    }
  },
  computed: {
    isImgLink() {
      const regex = /(https?:\/\/.*\.(?:png|jpg))/
      if (this.answer.image && typeof this.answer.image === 'string' && this.answer.image.match(regex)) return true
      return false
    },
    isAnswerModified() {
      return JSON.stringify(this.answer) !== this.answerReference
    },
  },
  mounted() {
    this.answerReference = JSON.stringify(this.answer)
  },
  methods: {
    removeImage() {
      this.answer.image = null
    },
    async submitForm() {
      try {
        this.formLoading = true
        this.server_errors = null

        const data = new FormData()
        // décommenter si on décode de ne pas le mettre obligatoire
        /* if (this.answer.title.fr !== '' && this.answer.title.fr !== null) {
          data.append('title_fr', this.answer.title.fr)
        }
        if (this.answer.en !== '' && this.answer.title.en !== null) {
          data.append('title_en', this.answer.title.en)
        } */
        data.append('title_fr', this.answer.title.fr)
        data.append('title_en', this.answer.title.en)
        data.append('title_de', this.answer.title.de)
        data.append('title_it', this.answer.title.it)
        data.append('title_es', this.answer.title.es)
        data.append('image', this.answer.image)
        const answer = await axiosIns.post(`answers/${this.answer.uuid}`, data)
        this.$emit('answerImageUpdate', answer.data.data)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La réponse à bien été mise a jour',
            },
          },
          { timeout: 5000 },
        )
        this.formLoading = false
      } catch (err) {
        this.formLoading = false
        this.server_errors = err.response.data.errors
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async deleteAnswer() {
      try {
        this.deleteLoading = true
        await axiosIns.delete(`answers/${this.answer.uuid}`)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La réponse a été supprimée',
            },
          },
          { timeout: 5000 },
        )
        this.deleteLoading = false
        this.$emit('answerDelete', this.answer.uuid)
        this.hideModal()
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
        this.deleteLoading = false
      }
    },
    validationForm() {
      this.$refs.answerValidation.validate().then(success => {
        if (success) {
          this.submitForm()
        }
      })
    },
    hideModal() {
      this.$refs[`modal-delete-answer-${this.answer.uuid}`].hide()
    },
  },
}
</script>

<style scoped>

</style>
